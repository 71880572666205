import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.sass';
import App from './App';
//import * as serviceWorker from './serviceWorker';

//import * as Klaro from 'klaro'
import * as Klaro from 'klaro/dist/klaro-no-css'
import { config } from './KlaroConfig'
import './klaro.css'

// we assign the Klaro module to the window, so that we can access it in JS
window.klaro = Klaro;
window.klaroConfig =  config;

// we set up Klaro with the config
//Klaro.setup(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
