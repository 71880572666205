import React from 'react';

export const LogoGrid = ({items, exclude})=>{

    const f = (i)=> exclude ? (exclude.indexOf( i.id ) === -1) : true;

    const logos = items.filter(f);

    return (
       
        <ul className="logogrid">
        { logos.map((i, c) => <LogoItem size="" key={c} {...i} />)}
        </ul>

    )

}

const LogoItem = (props) => (
    <li key={props.c} className={`${props.size}`}>
      <a target="_blank" rel="noopener noreferrer" href={props.link}>
        <img className="spacer" alt="proportion scaler" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAQAAACRI2S5AAAAEElEQVR42mNkIAAYRxWAAQAG9gAKqv6+AwAAAABJRU5ErkJggg==" />
        <img className="logoItem" src={props.img} alt="logo" />
      </a>
    </li>
  )  