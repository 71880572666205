export const config = {
  version: 2,
  cookieName: 'cpm_nebenanstiftung',
  lang: 'de',
  default: false,
  acceptAll: true,
  hideDeclineAll: false,
  htmlTexts: true,

  noticeAsModal: true,

  translations: {
    de: {
      privacyPolicyUrl: '/privacy',
      privacyPolicy: {
        name: 'Datenschutzerklärung',
        _text: 'Um mehr zu erfahren, lies bitte unsere {privacyPolicy}.',
        text: ' ',
      },
      consentModal: {
        title: 'Cookie-Einstellungen',
        old_description: 'Diese Seite verwendet Cookies. Dazu zählen notwendige Cookies (z.B. für eine sichere Anmeldung) sowie Cookies zur Erhebung statistischer Daten die uns helfen, diese Webseite weiter zu verbessern. Hier kannst Du die einzelnen Services individuell auswählen.',
        description: '<p class="klaro-p">Wenn du auf “Alle auswählen“ klickst, verarbeiten wir und weitere Drittanbieter jederzeit widerruflich deine personenbezogenen Daten und können Informationen durch Cookies auf deinem Endgerät speichern, bzw. auf diese zugreifen. Die Verarbeitung deiner personenbezogenen Daten erfolgt zum Zweck statistischer Auswertungen. Mit deinem Einverständnis hilfst du uns, diese Website und somit unser nachbarschaftliches Angebot weiter zu verbessern.</p><p class="klaro-p">Deine Einwilligung umfasst gem. Art. 49 Abs. 1 lit. a DSGVO auch die Übermittlung deiner personenbezogenen Daten in Drittländer, bspw. in die USA. In diesem Fall ist es möglich, dass die übermittelten Daten ohne richterlichen Beschluss durch lokale Behörden innerhalb des jeweiligen Drittlandes verarbeitet werden.</p><p class="klaro-p">Du kannst deine Präferenzen jetzt selbst bestimmen, jederzeit anpassen sowie deine freiwillige Einwilligung am Ende der Datenschutzerklärung der Webseite widerrufen. Weitere Informationen, insbesondere zu den eingesetzten Cookies und Dienstleistern, findest du ebenfalls in unserer <a href="/privacy">Datenschutzerklärung</a> und unserem <a href="/impress">Impressum</a>.</p>',
      },
      consentNotice: {
        title:'poko',
        changeDescription: 'Wir haben unsere Cookies geändert. Bitte erneuere Deine Wahl.',
        old_description: 'xxDiese Seite verwendet Cookies. Dazu zählen notwendige Cookies (z.B. für eine sichere Anmeldung) sowie Cookies zur Erhebung statistischer Daten die uns helfen, diese Webseite weiter zu verbessern. Klicke auf „Alle auswählen", um allen Cookies zuzustimmen.',
        description: '<p class="klaro-p">Wenn du auf “Alle auswählen“ klickst, verarbeiten wir und weitere Drittanbieter jederzeit widerruflich deine personenbezogenen Daten und können Informationen durch Cookies auf deinem Endgerät speichern, bzw. auf diese zugreifen. Die Verarbeitung deiner personenbezogenen Daten erfolgt zum Zweck statistischer Auswertungen. Mit deinem Einverständnis hilfst du uns, diese Website und somit unser nachbarschaftliches Angebot weiter zu verbessern.</p><p class="klaro-p">Deine Einwilligung umfasst gem. Art. 49 Abs. 1 lit. a DSGVO auch die Übermittlung deiner personenbezogenen Daten in Drittländer, bspw. in die USA. In diesem Fall ist es möglich, dass die übermittelten Daten ohne richterlichen Beschluss durch lokale Behörden innerhalb des jeweiligen Drittlandes verarbeitet werden.</p><p class="klaro-p">Du kannst deine Präferenzen jetzt selbst bestimmen, jederzeit anpassen sowie deine freiwillige Einwilligung am Ende der Datenschutzerklärung der Webseite widerrufen. Weitere Informationen, insbesondere zu den eingesetzten Cookies und Dienstleistern, findest du ebenfalls in unserer <a href="/privacy">Datenschutzerklärung</a> und unserem <a href="/impress">Impressum</a>.</p>',
        learnMore: 'Cookie-Einstellungen',
      },

      ok: 'Alle auswählen',
      purposes: {
        necessary: {
          title: 'Notwendige Cookies',
        },
        analytics: {
          title: 'Besucher-Statistiken',
        },
      },
    },
  },
  services: [
    {
      name: 'core',
      title: 'nebenan stiftung',
      default: true,
      required: true,
      purposes: ['necessary'],
      translations: {
        de: {
          description: 'Technische Cookies sind für das reibungslose Funktionieren der Website notwendig.',
        },
      },
    },
    {
      name: 'gtm',
      title: 'Google Analytics & Pardot',
      default: false,
      purposes: ['analytics'],
      translations: {
        de: {
          description: 'Hiermit werden Besucher-Statistiken mit Google Analytics und Pardot erfasst.',
        },
      },
      cookies: [
        [/^_ga.*$/i, '/', '.nebenan-stiftung.de'],
      ],
    },
  ],
}
