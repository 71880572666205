import React, { useRef, useState, useEffect, Fragment } from 'react';
import { Timeline } from 'react-twitter-widgets';

//import Instafeed from 'instafeed.js'

import './../styles/social.sass';
import { getStr } from './utils/String';

//var InstagramToken;

export const TwitterFeed = () => {
    const tag = useRef(null);
    const sscreenName = getStr('twitter_screen_name');

    console.log('twitter', sscreenName);

    useEffect( ()=>{
        console.log('twitter',tag.current)
    },[]) 

    return (
        <div className="twitter-wrap">
        <Timeline 
            ref= {tag}

            dataSource={{
                sourceType: 'profile',
                //screenName: 'twitterdev'
                screenName: sscreenName
            }}

            options={{
                //username: 'TwitterDev',
                height: '100%',
                width: '100%',
                //tweetLimit: 1,
                chrome: 'noheader, nofooter, noborders, transparent'
            }}
            onLoad={() => console.log('Timeline is loaded!')}
        />
        </div>
    )
}    


export const FacebookFeed = () => {

    const [wi, setwi] = useState(-1);
    const refWrapper = useRef(null);

    useEffect( ()=>{
        setwi(refWrapper.current.offsetWidth)
    },[wi,refWrapper]);

    return (        
        <div ref={refWrapper}>
            {wi !== -1 && <iframe src={`https://www.facebook.com/plugins/page.php?href=${getStr('facebook_page_url')}&tabs=timeline&width=${wi}&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=`+getStr('facebook_app_id')} title="facebook feed" width={wi} height="500" style={{border:"none",overflow:"hidden"}} scrolling="no" frameBorder="0" allow="encrypted-media"></iframe>}
        </div>
    )
}



export const InstagramFeed = ()=>{

    useEffect( ()=>{

        async function getToken( callBack ){
            //let data = await (await fetch('https://rs-photofeed-app.herokuapp.com/token.json')).json();            
      //      let data = await (await fetch('https://stiftung-instagram-agent.herokuapp.com/token.json')).json();            
            let data = await (await fetch('https://ig.instant-tokens.com/users/32b987fd-7d9e-4cb5-914e-26ca96803fe2/instagram/17841411442459256/token?userSecret=ixz0pv0xx9ojz2vviz03e')).json();
            return data;
            // var r = {}
            // r.token = 'IGQVJXenp0VXpYcFFwZAXExUVNXcEcyTTZApMzkzVnUyNXNxY3NQbjd1SW50Nnd4cU5IcG9wSlRpbU5MaU9uWUlLcURaa3BtemRVdEVzSGJTanQ1dE9wM3o2X1ZAQeF83RnBDNGw2ZA1ZAhUktYZAnVaY1V6RgZDZD';
            // return r
        }
        
        getToken().then( (tokenData)=>{

            console.log('TOKENDATA:',  tokenData );

            if (!tokenData.tokenData) return


            // var feed = new Instafeed({
            //     accessToken: tokenData.Token,
            //     //accessToken: 'IGQVJWWXQ0RkFFZA29YdWs2ZAXpoeHltdnJCcjdPbUVEUjZAPN3FZAdmZA6eW5YVmd6N1hUU09zN2RpZAExUS0ZAEdElCRWtvZAGZALUDN0bmQzUThHZAjVaNktndXBwMUJHTGF1UFhCSzY3SFF3',
            //     //debug: true,
            //     limit: 1,
            //     target: 'instafeed',            
            //     resolution: 'standard_resolution',
            //     template: '<a target="_blank" class="instaimg" href="{{link}}"><img class="wi-100" alt="img" src="{{image}}" /><div class="text-regular text-sm">{{caption}}</div></a>'
            //   });
            //   feed.run();
        });

    },[]) 


    return (
        <Fragment>
            <div id="instafeed" className="flex-y text-sm"></div>
        </Fragment>
    );
}