

export const trigger = (el, on, off)=>{

    // get the sticky elemen
    const stickyElm = (typeof el === 'string') ? document.querySelector( el ) : el;

    const observer = new IntersectionObserver( 
        ([e]) => {
            (e.intersectionRatio < 1) ? on && on.call(this,stickyElm) : off && off.call(this,stickyElm) ;
            e.target.classList.toggle('stuck', e.intersectionRatio < 1)
        },
        {threshold: [1]}
    );

    observer.observe(stickyElm)    


}