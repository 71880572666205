import React from 'react'
import { SharingIcons } from './SharingIcons'
import { Link } from "react-router-dom";


import { Logo } from './Header'
import { Str } from './utils/String'

export const Footer = () => {
    return (
        <footer className="bg-green text-color-white bg-trans-grad inner-shadow-top">
            <div className="container text-center pt-8 text-color-white m-pt-6 m-px-2">

                <Logo fill="#ffffff"></Logo>

                <div className="py-2"><Str id="footer.text.1"></Str></div>
                <div className="pt-2 pb-4"><Str id="footer.text.2"></Str></div>

                <div className="center text-bold"><Str id="footer.share.title"></Str></div>

                <SharingIcons className="pb-3 pt-2" />

                <nav className="navbar pb-4">
                    <ul className="navbar-nav flex-wrap align-items-center justify-content-center">
                        <li className="nav-item"><Link to="/privacy">Datenschutz</Link></li>
                        <li className="nav-item"><Link to="/impress">Impressum</Link></li>
                        <li className="nav-item"><Link to="/Newsletter">Newsletter</Link></li>
                    </ul>
                </nav>
            </div>
        </footer>
    )
}
