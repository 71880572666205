import React, { useEffect, useState } from "react";


export const useFileLoader = (filename) => {
  const [fileData, setFileData] = useState('');

  useEffect(()=>{

    fetch(filename).then((res)=>{
      res.text().then( (txt)=>{
        setFileData(txt)
      });
    })

  },[])

  return fileData
}