import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './App.sass';
import { Home } from './pages/Home';
import { Impress } from './pages/Impress';
import { Privacy } from './pages/Privacy';
import { Footer } from './components/Footer';
import { Newsletter } from './pages/Newsletter';
import { useLocation } from "react-router-dom/cjs/react-router-dom";

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-64660871-16');
// ReactGA.set({ anonymizeIp: true });
// ReactGA.pageview(window.location.pathname + window.location.search);


// import TagManager from 'react-gtm-module'
 
// const tagManagerArgs = {
//     gtmId: 'G-BCF0KH861Q'
// }
 
// TagManager.initialize(tagManagerArgs)


//Klaro.setup(config);


function App() {
  return (<Router>
    <KlaroChecker />
    <Switch >
      <Route path="/privacy" component={Privacy} /> 
      <Route path="/impress" component={Impress} /> 
      <Route path="/newsletter" component={Newsletter} /> 
      <Route path="/" component={Home}/> 
    </Switch> 
    <Footer />
  </Router>
  );
}

const KlaroChecker = () => {
  var loc = useLocation()
  useEffect( ()=>{
    if ( String(loc.pathname).indexOf('impress') === -1 && String(loc.pathname).indexOf('privacy') === -1){
      window.klaro.setup( window.klaroConfig );
    }
  },[loc])
  return <Fragment/>
}


export default App;