
import React, {useRef, useState, useEffect } from 'react';

export const Foldout = ({title, children, badgeClosed, badgeOpen}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOver, setIsOver] = useState(false);
    const childRef = useRef(null);
    const childWrapRef = useRef(null);

    const toggleSize = () => {
        setIsOpen( !isOpen );
        childWrapRef.current.style.height = ! isOpen ? `${childRef.current.clientHeight + 20}px` : ''
    }

    useEffect( ()=>{
      toggleSize()
    },[] )

    return (
        <div className="foldout flex-x pb-4" 
             data-isopen={isOpen ? 'yes' : 'no'} 
             data-isover={isOver ? 'yes' : 'no'} 
             onMouseOver={()=>setIsOver(true)} 
             onMouseOut={()=>setIsOver(false)}
             onClick={() => toggleSize()}
        >
            <div className="badge pr-2">
                <img className="badge-image" src={badgeClosed} alt="badge" />
                <img className="badge-image open" src={badgeOpen} alt="badge" />
            </div>
            <div className="text-grey content">                
                <div className="na-h3 pb-2 text-color-grey-dark flex-x flex-y-center">
                    <div className="flex-1">{title}</div>
                    {isOpen && <div onClick={() => toggleSize()} className="toggle"><IconClose /></div> }
                </div>
                <div className="child" ref={childWrapRef}>
                    <div className="text-body" ref={childRef}>{children}</div>
                    <div className="cover" />
                </div>
            </div>
        </div>
    );
}



const IconClose = ()=>{
    return(
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M8.34431658,7 L13.7215829,12.3772663 C14.0928057,12.7484891 14.0928057,13.3503601 13.7215829,13.7215829 C13.3503601,14.0928057 12.7484891,14.0928057 12.3772663,13.7215829 L7,8.34431658 L1.62273366,13.7215829 C1.25151089,14.0928057 0.649639855,14.0928057 0.278417081,13.7215829 C-0.0928056935,13.3503601 -0.0928056935,12.7484891 0.278417081,12.3772663 L5.65568342,7 L0.278417081,1.62273366 C-0.0928056935,1.25151089 -0.0928056935,0.649639855 0.278417081,0.278417081 C0.649639855,-0.0928056935 1.25151089,-0.0928056935 1.62273366,0.278417081 L7,5.65568342 L12.3772663,0.278417081 C12.7484891,-0.0928056935 13.3503601,-0.0928056935 13.7215829,0.278417081 C14.0928057,0.649639855 14.0928057,1.25151089 13.7215829,1.62273366 L8.34431658,7 Z" id="Combined-Shape" fill="#799189"></path>
            </g>
        </svg>
    ); 
}