import React, {useEffect, useRef} from 'react';

export const Parallax = ({src,mobilesrc, children,className, factor = 0.2, preshift=0})=>{

    const tagRef = useRef(false);
    const imgRef = useRef(false);
    const imgRefMobile = useRef(false);
    var cssMobileWi = getComputedStyle(document.documentElement).getPropertyValue('--media-mobile').replace('px ','');

    useEffect(()=>{

        window.addEventListener('resize', function(e) {
            adjustImagePosition();
        });

        window.addEventListener('scroll', function(e) {
            adjustImagePosition();
        });

        imgRef.current.addEventListener('load',()=>{
            adjustImagePosition();
        });

        const adjustImagePosition = () => {
            if (!tagRef.current){ return }

            var el = tagRef.current;
            var elTop    = el.getBoundingClientRect().top + el.ownerDocument.defaultView.pageYOffset
            var elBottom = el.getBoundingClientRect().bottom + el.ownerDocument.defaultView.pageYOffset
            var topOfs    = window.scrollY - elTop + window.innerHeight;
            var bottomOfs = window.scrollY - elBottom;

            if (topOfs > 0 && bottomOfs < 0){
                var ref = (mobilesrc && (window.innerWidth < cssMobileWi)) ? imgRefMobile.current : imgRef.current;
                var ofs =  topOfs /  (topOfs-bottomOfs);
                var shiftSize = (ref.offsetHeight - el.offsetHeight);
               // imgRef.current.style.top = Math.round( -shiftSize * ofs ) + 'px';
                ref.style.top = (Math.round( -shiftSize * ofs * factor )-preshift) + 'px';
            }


        }

    },[mobilesrc, cssMobileWi, factor]);

    return (
        <div ref={tagRef} className={"parallax " + (className ? className : '') }>
            <img ref={imgRef} src={src} alt="Parallax scroller" className={ mobilesrc ? 'mobile-hide' : ''}/>
            { mobilesrc && <img ref={imgRefMobile} src={mobilesrc} alt="Parallax scroller" className="mobile-show"/> }
            { children }
        </div>
    );
}