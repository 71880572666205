import React from 'react';

export const Featurebox = ({ title, img, children, id, url })=>{
    return (
        <a className="featurebox" id={id} href={url} target="_blank" rel="noopener noreferrer">
            <div className="inner">
                <div className="na-h3 title">{title}</div>
                <div className="img" style={ {backgroundImage:`url(/images/${img})`} }><img className="block" alt="propotion scaler" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAPCAQAAAC/mnxTAAAAFElEQVR42mNkIAswjmob1TaitQEAHQEAEDmBHRAAAAAASUVORK5CYII=" /></div>
                <div>
                    {children}
                </div>
            </div>
        </a>
    )

}