import React from 'react'

export const Photostack = ({src}) => {

    const style = { backgroundImage: 'url('+ src +')'}

    return (
        <div className="flex-x flex-x-center wi-100 relative photostack">
            <div className="photoframe shadow-md img-back"><img alt="proportion spacer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAAA/CAQAAACS54R5AAAAUUlEQVR42u3PAQ0AAAwCoNu/9APYwEEDcuMiKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKChYHiOoAEDFGwkfAAAAAElFTkSuQmCC" /></div>
            <div className="photoframe absolute shadow-md img-front" style={style}><img alt="proportion spacer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAAA/CAQAAACS54R5AAAAUUlEQVR42u3PAQ0AAAwCoNu/9APYwEEDcuMiKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKChYHiOoAEDFGwkfAAAAAElFTkSuQmCC" /></div>
        </div>
    )

}