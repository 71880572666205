import React from 'react';
import StringData from './../../string-data.json';

export const Str = (props)=>{

    
    var res = getStr(props.id, props.def);
    if (Object.keys(props).length > 2){
        Object.keys(props).map( (v,i)=>{
            res = String(res).replace('%'+v+'%', props[v] );
            return null;
        });
    }

    return (<span dangerouslySetInnerHTML={{__html:res}}></span>)
}

export const getStr = ( id, def ) =>{
    var res = StringData[id];    
    return res ? res : (typeof def !== 'undefined') ? def : `[${id}]`;
}

export const getNum = (id, def ) => {
    var r = getStr(id,def)
    return Number(r);
}

export const itterate_type = {
    parsed: 'parsed',
    idonly: 'idonly'
}

export const itterate = (ids, count, type) =>{
    var res = [];
    for (var a=1; a < count+1; a++){
        var rec = {};
        for (var n in ids){
            var idd = String(ids[n]).replace('[#]',a);
            switch (type){
                case itterate_type.idonly: 
                    rec[ n ] = idd; break;
                default:
                    rec[ n ] = getStr(idd,'');
            }
        }
        res.push(rec);
    }
    return res;
}