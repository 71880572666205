import React from 'react';
import { Header } from '../components/Header';
import { Str,itterate,getNum } from '../components/utils/String';
import { ScrollToTopOnMount } from '../components/utils/ScrollToTopOnMount';


export const Impress = ()=>{

    const menu = itterate({
        title: 'header.menuitem.[#].title',
        href : 'header.menuitem.[#].href'
    }, getNum('header.menuitem.count'))    

    return (
        <div>
            <ScrollToTopOnMount />
            <Header mode="subpage" menu={menu} logoLink="/"></Header>
            <div className="container-narrow text-body py-8 m-px-2">
                <Str id="impress.text"></Str>
            </div>
        </div>
    )
}