import { getStr } from "./components/utils/String"

function getUrl() {
    return encodeURIComponent(`${window.location.protocol}//${window.location.host}`)
}  

const shareText = encodeURIComponent(getStr('share.text'));

export const Config = {

    url_instagram: 'https://www.instagram.com/nebenan.de',
    url_nebenan: 'https://nebenan.de',
    url_facebook: getUrl(),
    url_twitter: getUrl(),
  
    facebook_sharetext: shareText,
    twitter_sharetext: shareText,
    whatsapp_sharetext: shareText,
  

}