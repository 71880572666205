import React from 'react'

export const HeaderMenuButton = ({isOpen, fill, onClick}) => {

    return (
        <div className="py-1 px-2">
            {!isOpen && <button className="flex-x flex-y-center" onClick={onClick}>
                <span className="text-bold text-uppercase pr-2">Menu</span> 
                <BurgerIcon fill={ fill }/>
            </button>}

            {isOpen && <button className="flex-x flex-y-center text-color-grey-link" onClick={onClick}>
                <span className="text-bold text-uppercase pr-2">schließen</span> 
                <CloseIcon fill={ fill } />
            </button> }
        </div>
    )
}


export const HeaderMenu = ({menu}) =>{

    return (
        <nav className="header-menu">
            <ul>
                { menu.map( (i,idx) => {
                    var isExternal = String(i.href).indexOf('http') === 0;
                    return (
                        <li key={idx} >
                            <a className="px-2" href={i.href} target={ isExternal ? '_blank' : '_self'  } rel="noreferrer">
                                {i.title}
                                { isExternal ? <LinkIcon /> : <></> }
                            </a>
                        </li>);
                })}
            </ul>
        </nav>
    )
}

const LinkIcon = ()=>{
    return(
        <svg className="link-icon" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Combined Shape</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M11,0 L11,2 L4,2 C2.8954305,2 2,2.8954305 2,4 L2,16 C2,17.1045695 2.8954305,18 4,18 L16,18 C17.1045695,18 18,17.1045695 18,16 L18,9 L20,9 L20,16 C20,18.209139 18.209139,20 16,20 L4,20 C1.790861,20 0,18.209139 0,16 L0,4 C0,1.790861 1.790861,0 4,0 L11,0 Z M20,0 L20,7 L17.2071068,4.20710678 L9.46446609,11.9497475 L8.05025253,10.5355339 L15.7928932,2.79289322 L13,0 L20,0 Z" id="Combined-Shape" fill="#9C9C9C" fillRule="nonzero"></path>
            </g>
        </svg>
    );    
}

const BurgerIcon = ({fill}) =>{
    return(
        <svg width="22px" height="18px" viewBox="0 0 22 18" version="1.1">
            <title>Shape</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M1.5,0.5 L20.5,0.5 C21.3284271,0.5 22,1.17157288 22,2 C22,2.82842712 21.3284271,3.5 20.5,3.5 L1.5,3.5 C0.671572875,3.5 0,2.82842712 0,2 C0,1.17157288 0.671572875,0.5 1.5,0.5 Z M1.5,7.5 L20.5,7.5 C21.3284271,7.5 22,8.17157288 22,9 C22,9.82842712 21.3284271,10.5 20.5,10.5 L1.5,10.5 C0.671572875,10.5 0,9.82842712 0,9 C0,8.17157288 0.671572875,7.5 1.5,7.5 Z M1.5,14.5 L20.5,14.5 C21.3284271,14.5 22,15.1715729 22,16 C22,16.8284271 21.3284271,17.5 20.5,17.5 L1.5,17.5 C0.671572875,17.5 0,16.8284271 0,16 C0,15.1715729 0.671572875,14.5 1.5,14.5 Z" id="Shape" fill={ fill ? fill : '#FFFFFF' }></path>
            </g>
        </svg>
    );
}

const CloseIcon = ()=>{
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Combined Shape</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M11.9204523,10 L19.6022613,17.6818091 C20.1325796,18.2121273 20.1325796,19.0719431 19.6022613,19.6022613 C19.0719431,20.1325796 18.2121273,20.1325796 17.6818091,19.6022613 L10,11.9204523 L2.31819095,19.6022613 C1.7878727,20.1325796 0.928056935,20.1325796 0.397738687,19.6022613 C-0.132579562,19.0719431 -0.132579562,18.2121273 0.397738687,17.6818091 L8.07954774,10 L0.397738687,2.31819095 C-0.132579562,1.7878727 -0.132579562,0.928056935 0.397738687,0.397738687 C0.928056935,-0.132579562 1.7878727,-0.132579562 2.31819095,0.397738687 L10,8.07954774 L17.6818091,0.397738687 C18.2121273,-0.132579562 19.0719431,-0.132579562 19.6022613,0.397738687 C20.1325796,0.928056935 20.1325796,1.7878727 19.6022613,2.31819095 L11.9204523,10 Z" id="Combined-Shape" fill="#799189"></path>
            </g>
        </svg>
    );
}