import React from 'react';
import { Header } from '../components/Header';
import { Str, itterate, getNum } from '../components/utils/String';
import { ScrollToTopOnMount } from '../components/utils/ScrollToTopOnMount';
import MailchimpSubscribe from "react-mailchimp-subscribe"

import * as Yup from 'yup'
import { Formik, Field } from 'formik';

const url = "https://tagdernachbarn.us20.list-manage.com/subscribe/post?u=18eb13aa68a6b7f78277115b9&amp;id=f25deb5509";


const FormField = ({
  field, form: { touched, errors }, label, info, arrow, ...props
}) => {
  const hasError = touched[field.name] && errors[field.name]

  return (
    <div className={`form-row ${hasError ? 'has-error' : ''}`}>
      <input type="text" className="form-control" placeholder="0" {...field} {...props} />
      <label htmlFor={field.name}>{label}</label>
      {info && <div className="field-info">{info}</div>}
      {hasError && <div className="field-error">{errors[field.name]}</div>}
      {arrow && <div className="field-arrow" />}
    </div>
  )
}

const FormCheckbox = ({
  field, form: { touched, errors }, label, info, arrow, ...props
}) => {
  const hasError = touched[field.name] && errors[field.name]

  return (
    <div className={`form-row ${hasError ? 'has-error' : ''}`}>
      <div className="flex-x flex-y-center">
        <input type="checkbox" className="form-control" placeholder="0" {...field} {...props} />
        <label style={{ position: 'relative', padding: '0px 10px' }} htmlFor={field.name}>{label}</label>
      </div>
      {info && <div className="field-info">{info}</div>}
      {hasError && <div className="field-error">{errors[field.name]}</div>}
      {arrow && <div className="field-arrow" />}
    </div>
  )
}




export const Newsletter = () => {

  const menu = itterate({
    title: 'header.menuitem.[#].title',
    href: 'header.menuitem.[#].href'
  }, getNum('header.menuitem.count'))

  return (
    <div>
      <ScrollToTopOnMount />
      <Header mode="subpage" menu={menu} logoLink="/"></Header>
      <div className="container-narrow text-body py-8 m-px-2" style={{ minHeight: 400 }}>

        <div className="na-h2 py-2"><Str id="newsletter.page.title"></Str></div>
        <div className="text-body py-2"><Str id="newsletter.page.text"></Str></div>

        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <div>

              <Formik
                initialValues={{
                  'EMAIL': '',
                  'FNAME': '',
                  'interestgroup_field': ''
                }}
                validationSchema={Yup.object().shape({
                  FNAME: Yup.string().required('Bitte deinen Vornamen eintragen.'),
                  EMAIL: Yup.string().email('Bitte deine E-Mail-Adresse eintragen.').required('Bitte deine E-Mail-Adresse eintragen.'),
                  interestgroup_field: Yup.bool().required('Bitte die Nutzungsbedingungen zustimmen'),
                })}

                onSubmit={(values, { setSubmitting }) => {
                  //console.log('ok')
                  subscribe(values);
                }}

              >{({ handleSubmit, isSubmitting }) => (

                <form onSubmit={handleSubmit}>

                  <div className="form-row">
                    <Field label="E-Mail Adresse *" type="email" name="EMAIL" className="form-control" component={FormField} />
                    {/* <label htmlFor="EMAIL">E-Mail Adresse</label> */}
                  </div>

                  <div className="form-row">
                    <Field label="Vorname *" type="text" name="FNAME" className="form-control" component={FormField} />
                  </div>

                  <div className="form">

                    <div className="text-bold py-1">Nutzungsbedingungen</div>
                    {/* <div className="pb-1">Bitte bestätige uns hier die Nutzungsbedingungen und dass wir dich per E-Mail über Neuigkeiten zu Projekten und Aktionen der nebenan.de Stiftung kontaktieren dürfen.</div> */}
                    <div className="flex-x flex-y-center">
                      {/* <Field type="checkbox" name="interestgroup_field" className="" component={FormCheckbox} label="Ich akzeptiere die Nutzungsbedingungen"/>  */}

                      <div className="flex-row">
                        <Field type="checkbox" name="interestgroup_field" className="" component={FormCheckbox} />
                        <div style={{ whiteSpace: 'normal' }}>Ich bin damit einverstanden, dass mir die nebenan.de Stiftung regelmäßig Newsletter zu ihren Initiativen, Veranstaltungen und Angeboten per E-Mail zusendet. Ich bin außerdem damit einverstanden, dass die nebenan.de Stiftung die Nutzung des Newsletters analysiert sowie zur Personalisierung ihrer Inhalte und Angebote verwendet. Meine Einwilligung ist jederzeit widerrufbar.  Weitere Informationen zu der Verarbeitung der personenbezogenen Daten kann ich <a href="/privacy">hier</a> abrufen.</div>
                      </div>

                    </div>
                    {/* <div className="pt-2 pb-2">
                      Du kannst dich jederzeit abmelden, indem du auf den Link in der Fußzeile unserer E-Mails klickst. Informationen zu unseren Datenschutzpraktiken findest du auf unserer Website.
                    </div> */}
                  </div>

                  <div className="pt-2">
                    <button className="btn bg-green " type="submit" disabled={isSubmitting}>Newsletter Anmelden</button>
                  </div>
                </form>
              )}

              </Formik>
              {message && <div className="pt-3 text-regular">{message}</div>}
            </div>


          )}></MailchimpSubscribe>
      </div>
    </div>
  )
}

