import React, { useEffect, useRef, useState  } from 'react'
import { HeaderMenu, HeaderMenuButton } from './HeaderMenu'
import { trigger } from './utils/StickeyTracker'



const menu_height = 60;
const color_white = '#ffffff';
const color_link = '#799189';


export const Header = ({menu,mode,logoLink,children}) => {
    var classes = "bg-green-gradient "
    
    const stickeyRef = useRef(null);
    const [ isOpen, setIsOpen] = useState(false);
    const [ isSmall, setIsSmall] = useState(false);

    useEffect( ()=>{

        //sticky trigger
        trigger(stickeyRef.current, (el)=>{ 
            applyHeight(el,true);
            setIsSmall(true);
        }, (el)=>{
            applyHeight(el,false);
            setIsSmall(false);
        });

    },[])


    const applyHeight = (el, opt)=>{
        el.style.minHeight = opt ?  menu_height + 'px' : '';
        //el.style.minHeight = opt ?  menu_height el.firstChild.clientHeight + 'px' : '';
    }

    const toggleMenu = ()=>{
        setIsOpen( !isOpen );
        applyHeight( stickeyRef.current, !isOpen );        
    }

    switch (mode){
        case 'skyline': 
            classes += 'skyline'; 
            break;
        case 'subpage': 
            classes += 'subpage'; 
            break;
        default: 
            classes += '';
            break;
    }

    const fillColor = isOpen || isSmall ? color_link : color_white;

    return (
       <header className={classes} menu={ isOpen ? 'open' : 'closed' }>

            {isOpen && <div className="cover" onClick={ (i)=>toggleMenu()  } />}

            <div ref={stickeyRef} className="stickey">
                <div className="bar relative wi-100">
                    <div className="container-wide flex-x py-10px flex-y-center">
                        <div className="flex-1 pl-1">
                            { logoLink ? (
                                <a href={logoLink}><Logo fill={fillColor}></Logo></a>
                            ):(
                                <Logo fill={fillColor}></Logo>
                            )}
                        </div>
                        <HeaderMenuButton isOpen={isOpen} fill={fillColor} onClick={ (i)=>toggleMenu() }/>
                    </div>
                </div>
            </div>

            <HeaderMenu menu={menu} />

            <div className="wrapper">
                {children}
            </div>
        </header>
    )
}



export const Logo = (props)=>{
    return(
        <svg className="logo" width="211px" height="84px" viewBox="0 0 211 84" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(0.822400, 0.312050)" fill={props.fill || '#ffffff'}>
                    <path d="M86.8661,58.52335 L87.1671,58.07335 C87.5421,57.54635 87.9691,57.49735 88.5951,57.82235 C89.1961,58.14735 90.1481,58.69935 91.4521,58.69935 C92.5541,58.69935 93.2311,58.19835 93.2311,57.39535 C93.2311,55.31535 86.8911,56.09235 86.8911,51.70835 C86.8911,49.20235 89.0961,47.87435 91.7771,47.87435 C93.4811,47.87435 94.6591,48.42535 95.3351,48.80135 C95.9111,49.10135 95.9871,49.62735 95.7121,50.20535 L95.4611,50.65635 C95.1601,51.25635 94.6841,51.33235 94.0581,51.05635 C93.5061,50.80535 92.6791,50.42935 91.6271,50.42935 C90.5241,50.42935 89.8981,50.88035 89.8981,51.68235 C89.8981,53.78835 96.2381,52.96135 96.2381,57.32135 C96.2381,59.57635 94.3331,61.25435 91.3771,61.25435 C89.3221,61.25435 87.8931,60.47735 87.1161,59.95235 C86.5401,59.62635 86.4901,59.07535 86.8661,58.52335" id="Fill-1"></path>
                    <path d="M98.7704,50.88075 L98.0934,50.88075 C97.4164,50.88075 97.0654,50.52975 97.0654,49.82875 L97.0654,49.40275 C97.0654,48.72575 97.4164,48.34975 98.1194,48.34975 L98.8454,48.34975 L98.8454,45.74375 C98.8454,45.06875 99.2214,44.69275 99.8974,44.69275 L100.9004,44.69275 C101.5764,44.69275 101.9524,45.06875 101.9524,45.74375 L101.9524,48.34975 L103.8074,48.34975 C104.4824,48.34975 104.8594,48.72575 104.8594,49.40275 L104.8594,49.82875 C104.8594,50.52975 104.4824,50.88075 103.8314,50.88075 L101.9524,50.88075 L101.9524,55.84175 C101.9524,57.74675 103.1294,58.12275 104.0064,58.22275 C104.7834,58.29775 105.0604,58.54875 105.0604,59.24975 L105.0604,60.02675 C105.0604,60.77875 104.6334,61.07975 103.7564,61.07975 C101.4764,61.07975 98.7704,59.85175 98.7704,56.39375 L98.7704,50.88075 Z" id="Fill-3"></path>
                    <path d="M106.6395,49.22745 C106.6395,48.55045 106.9925,48.17545 107.6665,48.17545 L108.7695,48.17545 C109.4465,48.17545 109.7965,48.55045 109.7965,49.22745 L109.7965,59.90245 C109.7965,60.60345 109.4465,60.95445 108.7695,60.95445 L107.6665,60.95445 C106.9925,60.95445 106.6395,60.60345 106.6395,59.90245 L106.6395,49.22745 Z M106.6395,44.96745 L106.6395,44.21545 C106.6395,43.53945 106.9925,43.16345 107.6665,43.16345 L108.7695,43.16345 C109.4465,43.16345 109.8225,43.53945 109.8225,44.21545 L109.8225,44.96745 C109.8225,45.64445 109.4465,45.99445 108.7695,45.99445 L107.6665,45.99445 C106.9925,45.99445 106.6395,45.64445 106.6395,44.96745 L106.6395,44.96745 Z" id="Fill-5"></path>
                    <path d="M113.0809,50.88075 L112.4549,50.88075 C111.7769,50.88075 111.4269,50.52975 111.4269,49.82875 L111.4269,49.40275 C111.4269,48.72575 111.7769,48.34975 112.4549,48.34975 L113.0809,48.34975 L113.0809,47.74875 C113.0809,43.69075 116.6639,43.06275 117.9669,43.06275 L118.3429,43.06275 C119.0179,43.06275 119.3689,43.41375 119.3689,44.11575 L119.3689,44.76675 C119.3689,45.44375 118.9929,45.74375 118.2919,45.79475 C117.5159,45.84475 116.2629,46.01975 116.2629,47.87375 L116.2629,48.34975 L117.9669,48.34975 C118.6429,48.34975 118.9929,48.72575 118.9929,49.40275 L118.9929,49.82875 C118.9929,50.52975 118.6429,50.88075 117.9669,50.88075 L116.2629,50.88075 L116.2629,59.90175 C116.2629,60.60375 115.8879,60.95375 115.2099,60.95375 L114.1069,60.95375 C113.4319,60.95375 113.0809,60.60375 113.0809,59.90175 L113.0809,50.88075 Z" id="Fill-7"></path>
                    <path d="M121.5272,50.88075 L120.8492,50.88075 C120.1732,50.88075 119.8232,50.52975 119.8232,49.82875 L119.8232,49.40275 C119.8232,48.72575 120.1732,48.34975 120.8762,48.34975 L121.6012,48.34975 L121.6012,45.74375 C121.6012,45.06875 121.9772,44.69275 122.6552,44.69275 L123.6572,44.69275 C124.3332,44.69275 124.7092,45.06875 124.7092,45.74375 L124.7092,48.34975 L126.5632,48.34975 C127.2402,48.34975 127.6162,48.72575 127.6162,49.40275 L127.6162,49.82875 C127.6162,50.52975 127.2402,50.88075 126.5882,50.88075 L124.7092,50.88075 L124.7092,55.84175 C124.7092,57.74675 125.8862,58.12275 126.7632,58.22275 C127.5412,58.29775 127.8172,58.54875 127.8172,59.24975 L127.8172,60.02675 C127.8172,60.77875 127.3892,61.07975 126.5132,61.07975 C124.2332,61.07975 121.5272,59.85175 121.5272,56.39375 L121.5272,50.88075 Z" id="Fill-9"></path>
                    <path d="M129.2206,49.22745 C129.2206,48.55045 129.5966,48.17545 130.2736,48.17545 L131.3506,48.17545 C132.0526,48.17545 132.4046,48.55045 132.4046,49.22745 L132.4046,55.74245 C132.4046,57.29545 132.8036,58.34845 134.3826,58.34845 C136.6886,58.34845 137.9656,56.31845 137.9656,54.03845 L137.9656,49.22745 C137.9656,48.55045 138.3166,48.17545 139.0176,48.17545 L140.0956,48.17545 C140.7726,48.17545 141.1486,48.55045 141.1486,49.22745 L141.1486,59.90245 C141.1486,60.60345 140.7726,60.95445 140.0956,60.95445 L139.1186,60.95445 C138.4426,60.95445 138.0916,60.60345 138.0916,59.90245 L138.0916,59.15045 C138.0916,58.84945 138.1416,58.57345 138.1416,58.57345 L138.0916,58.57345 C137.4906,59.87645 135.8366,61.25545 133.6576,61.25545 C130.9256,61.25545 129.2206,59.87645 129.2206,56.39445 L129.2206,49.22745 Z" id="Fill-11"></path>
                    <path d="M143.7313,49.22745 C143.7313,48.55045 144.0843,48.17545 144.7583,48.17545 L145.7363,48.17545 C146.4123,48.17545 146.7633,48.55045 146.7633,49.22745 L146.7633,50.00445 C146.7633,50.27945 146.7133,50.58045 146.7133,50.58045 L146.7633,50.58045 C147.3143,49.50345 148.7173,47.87345 151.2993,47.87345 C154.1303,47.87345 155.7593,49.35245 155.7593,52.73545 L155.7593,59.90245 C155.7593,60.60345 155.4083,60.95445 154.7083,60.95445 L153.6293,60.95445 C152.9523,60.95445 152.5773,60.60345 152.5773,59.90245 L152.5773,53.38745 C152.5773,51.83245 152.1753,50.78045 150.5723,50.78045 C148.3163,50.78045 146.8883,52.66045 146.8883,54.96545 L146.8883,59.90245 C146.8883,60.60345 146.5383,60.95445 145.8613,60.95445 L144.7583,60.95445 C144.0843,60.95445 143.7313,60.60345 143.7313,59.90245 L143.7313,49.22745 Z" id="Fill-13"></path>
                    <path d="M166.8378,54.28895 C166.8378,51.35695 165.3838,50.58095 163.7318,50.58095 C161.7518,50.58095 160.6738,51.98395 160.6738,54.08895 C160.6738,56.39295 161.8258,58.02295 163.8808,58.02295 C165.3838,58.02295 166.8378,57.14495 166.8378,54.28895 M160.5728,63.03395 C161.1748,63.25995 161.9768,63.50995 163.1538,63.50995 C165.1088,63.50995 166.7628,62.68395 166.7628,60.40295 L166.7628,59.45095 C166.7628,59.14995 166.8138,58.79895 166.8138,58.79895 L166.7628,58.79895 C166.0608,60.00195 164.9588,60.70395 163.2548,60.70395 C159.5218,60.70395 157.4668,57.74695 157.4668,54.21395 C157.4668,50.73095 159.4208,47.87395 163.2308,47.87395 C166.2118,47.87395 167.0128,49.82895 167.0128,49.82895 L167.0648,49.82895 C167.0648,49.82895 167.0388,49.70395 167.0388,49.50295 L167.0388,49.02695 C167.0388,48.55095 167.3898,48.17495 168.0668,48.17495 L168.9178,48.17495 C169.5938,48.17495 169.9448,48.55095 169.9448,49.22695 L169.9448,60.17695 C169.9448,64.56195 166.4868,66.21595 163.3298,66.21595 C161.9008,66.21595 160.4728,65.91595 159.5958,65.53995 C158.9448,65.26395 158.7698,64.83795 159.0448,64.18595 L159.2458,63.61095 C159.5218,62.98395 159.8978,62.80895 160.5728,63.03395" id="Fill-15"></path>
                    <path d="M68.267,60.39925 L63.383,63.23825 L63.383,49.31625 C63.383,48.30125 62.838,47.35925 61.96,46.85325 C61.082,46.34425 59.992,46.34725 59.119,46.85325 L49.982,52.11425 C48.224,53.12525 47.131,55.01325 47.131,57.04425 L47.131,72.68825 L39.817,76.94125 L39.817,43.57625 L69.884,26.09525 L69.884,57.58925 C69.884,58.74425 69.265,59.82025 68.267,60.39925 L68.267,60.39925 Z M27.629,72.68825 L27.629,57.04425 C27.629,55.01325 26.536,53.12525 24.779,52.11625 L15.645,46.85625 C14.767,46.34425 13.677,46.34725 12.8,46.85325 C11.922,47.35925 11.377,48.30125 11.377,49.31625 L11.377,63.23825 L6.492,60.39925 C5.495,59.82025 4.876,58.74425 4.876,57.58925 L4.876,26.09525 L34.942,43.57625 L34.942,76.94125 L27.629,72.68825 Z M34.942,5.77925 L34.942,37.93525 L7.289,21.85725 L34.942,5.77925 Z M67.471,21.85725 L39.817,37.93525 L39.817,5.78025 L67.471,21.85725 Z M70.718,18.10625 L41.463,1.09725 C38.945,-0.36575 35.816,-0.36575 33.295,1.09725 L4.043,18.10625 C1.549,19.55425 7.10542736e-15,22.24525 7.10542736e-15,25.12925 L7.10542736e-15,57.58925 C7.10542736e-15,60.47425 1.549,63.16725 4.042,64.61425 L16.252,71.71225 L16.252,52.83225 L22.348,56.34125 C22.598,56.48525 22.753,56.75425 22.753,57.04425 L22.753,75.49425 L33.296,81.62325 C34.556,82.35525 35.967,82.72125 37.38,82.7192582 C38.791,82.7192582 40.204,82.35525 41.464,81.62325 L52.006,75.49425 L52.006,57.04425 C52.006,56.75425 52.162,56.48525 52.413,56.34025 L58.507,52.83225 L58.507,71.71225 L70.717,64.61425 C73.21,63.16725 74.759,60.47425 74.759,57.58925 L74.759,25.12925 C74.759,22.24725 73.211,19.55525 70.718,18.10625 L70.718,18.10625 Z" id="Fill-17"></path>
                    <path d="M86.6254,23.87345 C86.6254,23.18845 86.9894,22.80845 87.6894,22.80845 L88.7004,22.80845 C89.4004,22.80845 89.7634,23.18845 89.7634,23.87345 L89.7634,24.66045 C89.7634,24.93845 89.7114,25.24345 89.7114,25.24345 L89.7634,25.24345 C90.3334,24.15345 91.7854,22.50445 94.4564,22.50445 C97.3854,22.50445 99.0714,23.99945 99.0714,27.42445 L99.0714,34.67745 C99.0714,35.38745 98.7084,35.74345 97.9814,35.74345 L96.8674,35.74345 C96.1674,35.74345 95.7784,35.38745 95.7784,34.67745 L95.7784,28.08345 C95.7784,26.51145 95.3634,25.44645 93.7044,25.44645 C91.3714,25.44645 89.8924,27.34745 89.8924,29.68145 L89.8924,34.67745 C89.8924,35.38745 89.5294,35.74345 88.8294,35.74345 L87.6894,35.74345 C86.9894,35.74345 86.6254,35.38745 86.6254,34.67745 L86.6254,23.87345 Z" id="Fill-19"></path>
                    <path d="M109.9813,27.80405 C109.9293,26.00405 108.8143,24.93905 107.3113,24.93905 C105.6263,24.93905 104.4323,26.08005 104.1213,27.80405 L109.9813,27.80405 Z M107.3883,22.50405 C111.1483,22.50405 113.2483,25.11605 113.2483,28.66705 C113.2483,29.22505 112.7553,29.75705 112.1073,29.75705 L104.0443,29.75705 C104.2523,32.09005 106.0143,33.30705 108.0103,33.30705 C109.2293,33.30705 110.2403,32.85205 110.8623,32.47105 C111.5103,32.16605 111.9773,32.24305 112.3143,32.82705 L112.6263,33.33405 C112.9363,33.91705 112.8333,34.42505 112.2623,34.75305 C111.4073,35.28605 109.8513,36.04805 107.7773,36.04805 C103.3963,36.04805 100.6733,32.95305 100.6733,29.27505 C100.6733,25.29405 103.4213,22.50405 107.3883,22.50405 L107.3883,22.50405 Z" id="Fill-21"></path>
                    <path d="M121.4452,33.33385 C123.2852,33.33385 124.7892,31.88785 124.7892,29.32585 C124.7892,26.89185 123.4422,25.26885 121.5222,25.26885 C119.8112,25.26885 118.1782,26.51085 118.1782,29.27485 C118.1782,31.38085 119.2932,33.33385 121.4452,33.33385 L121.4452,33.33385 Z M114.9882,18.80085 C114.9882,18.11485 115.3792,17.73585 116.0782,17.73585 L117.1922,17.73585 C117.9202,17.73585 118.2812,18.11485 118.2812,18.80085 L118.2812,23.49185 C118.2812,24.12685 118.2032,24.58285 118.2032,24.58285 L118.2562,24.58285 C118.2562,24.58285 119.4242,22.50385 122.3002,22.50385 C125.8012,22.50385 128.1342,25.26885 128.1342,29.24985 C128.1342,33.38485 125.5152,36.04785 122.0932,36.04785 C119.1112,36.04785 118.0492,33.86685 118.0492,33.86685 L117.9962,33.86685 C117.9962,33.86685 118.0492,34.06885 118.0492,34.42485 L118.0492,34.82885 C118.0492,35.38685 117.6852,35.74285 116.9602,35.74285 L116.0782,35.74285 C115.3792,35.74285 114.9882,35.38685 114.9882,34.67785 L114.9882,18.80085 Z" id="Fill-23"></path>
                    <path d="M138.495,27.80405 C138.443,26.00405 137.328,24.93905 135.825,24.93905 C134.14,24.93905 132.946,26.08005 132.635,27.80405 L138.495,27.80405 Z M135.902,22.50405 C139.662,22.50405 141.763,25.11605 141.763,28.66705 C141.763,29.22505 141.269,29.75705 140.621,29.75705 L132.558,29.75705 C132.766,32.09005 134.528,33.30705 136.524,33.30705 C137.743,33.30705 138.754,32.85205 139.376,32.47105 C140.024,32.16605 140.491,32.24305 140.828,32.82705 L141.14,33.33405 C141.45,33.91705 141.348,34.42505 140.776,34.75305 C139.921,35.28605 138.365,36.04805 136.291,36.04805 C131.91,36.04805 129.187,32.95305 129.187,29.27505 C129.187,25.29405 131.935,22.50405 135.902,22.50405 L135.902,22.50405 Z" id="Fill-25"></path>
                    <path d="M143.5028,23.87345 C143.5028,23.18845 143.8658,22.80845 144.5658,22.80845 L145.5768,22.80845 C146.2768,22.80845 146.6408,23.18845 146.6408,23.87345 L146.6408,24.66045 C146.6408,24.93845 146.5888,25.24345 146.5888,25.24345 L146.6408,25.24345 C147.2108,24.15345 148.6618,22.50445 151.3318,22.50445 C154.2628,22.50445 155.9468,23.99945 155.9468,27.42445 L155.9468,34.67745 C155.9468,35.38745 155.5858,35.74345 154.8578,35.74345 L153.7438,35.74345 C153.0448,35.74345 152.6538,35.38745 152.6538,34.67745 L152.6538,28.08345 C152.6538,26.51145 152.2398,25.44645 150.5798,25.44645 C148.2468,25.44645 146.7698,27.34745 146.7698,29.68145 L146.7698,34.67745 C146.7698,35.38745 146.4058,35.74345 145.7058,35.74345 L144.5658,35.74345 C143.8658,35.74345 143.5028,35.38745 143.5028,34.67745 L143.5028,23.87345 Z" id="Fill-27"></path>
                    <path d="M162.6112,33.58675 C164.4522,33.58675 165.6432,31.76175 165.6432,30.18875 L165.6432,29.70675 L165.0992,29.70675 C163.4412,29.70675 160.6142,29.95975 160.6142,31.88775 C160.6142,32.74975 161.2892,33.58675 162.6112,33.58675 M165.0742,27.75375 L165.6192,27.75375 L165.6192,27.44975 C165.6192,25.75075 164.5042,25.16575 163.1552,25.16575 C162.0412,25.16575 161.0302,25.57175 160.4072,25.90175 C159.7572,26.18075 159.2662,26.07975 158.9552,25.47075 L158.6952,24.98875 C158.3842,24.40575 158.4882,23.89875 159.1102,23.56775 C159.9652,23.13775 161.4682,22.50375 163.4412,22.50375 C166.8632,22.50375 168.9112,24.37975 168.9112,27.65175 L168.9112,34.67775 C168.9112,35.38775 168.5482,35.74275 167.8222,35.74275 L166.9412,35.74275 C166.2402,35.74275 165.8782,35.38775 165.8782,34.67775 L165.8782,34.16975 C165.8782,33.81475 165.9282,33.61175 165.9282,33.61175 L165.8782,33.61175 C165.8782,33.61175 164.6322,36.04775 161.7292,36.04775 C159.3192,36.04775 157.3462,34.52575 157.3462,32.16575 C157.3462,28.38675 162.1182,27.75375 165.0742,27.75375" id="Fill-29"></path>
                    <path d="M171.2938,23.87345 C171.2938,23.18845 171.6578,22.80845 172.3578,22.80845 L173.3688,22.80845 C174.0688,22.80845 174.4318,23.18845 174.4318,23.87345 L174.4318,24.66045 C174.4318,24.93845 174.3798,25.24345 174.3798,25.24345 L174.4318,25.24345 C175.0018,24.15345 176.4538,22.50445 179.1248,22.50445 C182.0538,22.50445 183.7398,23.99945 183.7398,27.42445 L183.7398,34.67745 C183.7398,35.38745 183.3768,35.74345 182.6498,35.74345 L181.5358,35.74345 C180.8358,35.74345 180.4478,35.38745 180.4478,34.67745 L180.4478,28.08345 C180.4478,26.51145 180.0318,25.44645 178.3728,25.44645 C176.0398,25.44645 174.5608,27.34745 174.5608,29.68145 L174.5608,34.67745 C174.5608,35.38745 174.1978,35.74345 173.4978,35.74345 L172.3578,35.74345 C171.6578,35.74345 171.2938,35.38745 171.2938,34.67745 L171.2938,23.87345 Z" id="Fill-31"></path>
                    <path d="M186.1405,34.19815 C186.1405,33.70315 186.4035,33.44615 186.9275,33.44615 L187.7515,33.44615 C188.2595,33.44615 188.5395,33.70315 188.5395,34.19815 L188.5395,35.02315 C188.5395,35.53715 188.2595,35.79315 187.7515,35.79315 L186.9275,35.79315 C186.4035,35.79315 186.1405,35.53715 186.1405,35.02315 L186.1405,34.19815 Z" id="Fill-33"></path>
                    <path d="M194.3778,34.05115 C195.6158,34.05115 196.7778,33.17115 196.7778,31.09915 C196.7778,29.65015 196.0098,28.22015 194.3988,28.22015 C193.1038,28.22015 191.9968,29.26515 191.9968,31.11715 C191.9968,32.89515 192.9718,34.05115 194.3778,34.05115 M193.9288,26.22115 C196.0278,26.22115 196.7218,27.55915 196.7218,27.55915 L196.7588,27.55915 C196.7588,27.55915 196.7218,27.32115 196.7218,27.02715 L196.7218,23.54315 C196.7218,23.04815 196.9838,22.77315 197.4908,22.77315 L198.3158,22.77315 C198.8218,22.77315 199.0848,23.04815 199.0848,23.54315 L199.0848,35.02315 C199.0848,35.53715 198.8218,35.79315 198.3158,35.79315 L197.6218,35.79315 C197.1158,35.79315 196.8538,35.55515 196.8538,35.09715 L196.8538,34.72915 C196.8538,34.54615 196.8908,34.39915 196.8908,34.39915 L196.8538,34.39915 C196.8538,34.39915 196.0468,36.01415 193.8538,36.01415 C191.2648,36.01415 189.5958,34.01515 189.5958,31.11715 C189.5958,28.14615 191.3978,26.22115 193.9288,26.22115" id="Fill-35"></path>
                    <path d="M207.1102,30.05405 C207.0732,28.75105 206.2672,27.98105 205.1782,27.98105 C203.9592,27.98105 203.0972,28.80705 202.8722,30.05405 L207.1102,30.05405 Z M205.2352,26.22105 C207.9542,26.22105 209.4732,28.10905 209.4732,30.67705 C209.4732,31.08105 209.1162,31.46505 208.6482,31.46505 L202.8162,31.46505 C202.9662,33.15305 204.2412,34.03305 205.6852,34.03305 C206.5672,34.03305 207.2982,33.70305 207.7482,33.42805 C208.2162,33.20805 208.5542,33.26305 208.7982,33.68505 L209.0232,34.05105 C209.2482,34.47305 209.1732,34.84005 208.7602,35.07805 C208.1422,35.46305 207.0162,36.01405 205.5162,36.01405 C202.3462,36.01405 200.3792,33.77605 200.3792,31.11705 C200.3792,28.23805 202.3662,26.22105 205.2352,26.22105 L205.2352,26.22105 Z" id="Fill-37"></path>
                </g>
            </g>
        </svg>        
    )
}